export default {
  api: 'https://api.lectogo.fusionworks.md/api',
  app: 'https://app.lectogo.com/api',
  googleKey: 'AIzaSyCaQDgJhmJGXw-cV4D80Oz88FawjbkhICs',
  routes: {
    stations: 'station',
    locations: 'location',
    campaigns: 'campaign',
    countries: 'country',
    currencies: 'currency',
    locationCategories: 'location-category',
    discounts: 'discount',
    partners: 'partner',
    partnersBalance: 'partner-balance',
    notificationStore: 'notification-store',
    powerbanks: 'powerbank',
    leases: 'lease',
    leasesEvents: 'lease-log',
    users: 'account',
    promoCodes: 'promocode',
    subscribtionCodes: 'subscribtionCode',
    transactions: 'transaction',
    promotions: 'promotion',
    voucherTemplates: 'voucherTemplate',
    vouchers: 'voucher',
    prices: 'price',
    topupTemplate: 'topup-template',
    fees: 'fees',
    subscriptionConfig: 'subscription-config',
    stationsRack: 'stations-rack',
    dayPassConfig: 'daypass-config',
  },
};
